<template>
  <div class="teufel-history">
    <GoliathImage
      alt="label"
      path="/content/content-images/virtual-shelf/teufel/c6393e495538c8f9404ff8bb4854c16a/seit-1979@2x.webp"
      class="teufel-history__since-1979"
    />
    <div class="teufel-history__container">
      <div class="teufel-history__1979">
        <div class="teufel-history__text-container">
          <Typography
            ref="heading"
            variant="heading"
            class="teufel-history__heading"
          >
            Von DIY zu High-End Produkten aus einer Hand
          </Typography>
          <Typography
            ref="heading"
            variant="flow-text"
            class="teufel-history__text"
          >
            1979 galt HiFi noch als zu schwer, zu teuer und zu kompliziert. Unsere Lautsprecher Bausätze brachten die Revolution und machten guten Sound einfach und erschwinglich. Eine neue Idee. Die Marke Teufel war geboren.
          </Typography>
        </div>
        <GoliathImage
          alt="label"
          path="/content/content-images/virtual-shelf/teufel/cadcc3acc2a6b730e8a9665a8b159b83/keyvisual-45-jahre-kombi@2x.webp"
          class="teufel-history__45years-kombi"
        />
      </div>
      <div class="teufel-history__1979">
        <div class="teufel-history__text-container teufel-history__text-container--2024">
          <Typography
            ref="heading"
            variant="heading"
            class="teufel-history__heading"
          >
            Innovation – Die guten neuen Zeiten
          </Typography>
          <Typography
            ref="heading"
            variant="flow-text"
            class="teufel-history__text"
          >
            Die Teufel Story ist für uns kein nostalgischer Rückblick, sondern das Intro für unsere nächsten Hits. Mit Leidenschaft für guten Sound entwickeln und designen wir mitten in Berlin außergewöhnliche Neuheiten und verbessern unsere Klassiker.
          </Typography>
        </div>
        <GoliathImage
          alt="label"
          path="/content/content-images/virtual-shelf/teufel/247ae1fe0c5f464c7c69495171cca210/keyvisual-teufel-2024-kombi@2x.webp"
          class="teufel-history__2024-kombi"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/typography';

import GoliathImage from '@/components/goliath-image';

export default {
  name: 'TeufelHistory',
  components: {
    Typography,
    GoliathImage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/style.scss";

.teufel-history {
  display: flex;
  flex-direction: column;

  &__since-1979 {
    z-index: 1;
    margin-top: 192px;
    margin-bottom: -10px;
    width: 880px;
    margin-left: 75px;
  }

  &__container {
    display: flex;
    padding-left: 75px;
    margin-right: 150px;
    background-color: #F8F8F8;
    height: 100%;
  }

  &__1979 {
    position: relative;
    width: 1500px;
    margin-left: 150px;
    margin-right: -70px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__text-container {
    position: relative;
    z-index: 1;

    &--2024 {
      margin-top: 550px;
    }
  }

  &__heading {
    margin-top: 72px;
    margin-bottom: 48px;
    max-width: 728px;
    font-weight: 800;
    text-transform: none;
  }
  &__text {
    max-width: 921px;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
  }

  &__45years-kombi {
    position: absolute;
    left: 0;
    top: 300px;
    width: 1446px;
    z-index: 0;
  }

  &__2024-kombi {
    position: absolute;
    left: 0;
    top: -200px;
    width: 1600px;
    z-index: 0;
  }

}
</style>
