<template>
  <div class="offer-page">
    <Header />
    <div
      v-if="offer && offer.product"
      class="offer-page__main"
    >
      <Typography
        class="offer-page__heading"
        variant="heading"
      >
        <div class="offer-page__heading-block">
          {{ productHeading }}
          <CounterBadge
            v-if="offerQuantity"
            :count="offerQuantity"
          />
        </div>
      </Typography>
      <ImageGallery
        :has-multiple-images="hasMultipleImages"
        :image-data="imageData"
        :rebate="rebate"
        :show-customizable-badge="isPartialCustomizable"
        :show-discount="hasDiscount"
      />

      <div class="offer-page__content">
        <EnergyLabelContainer
          :product="offer.product"
          class="offer-page__energy-label"
          datasheet-alignment="left"
        />

        <Prices
          :legal-text="legalTexts"
          :prices-data="prices"
          class="offer-page__prices"
        />
        <router-link
          v-if="isPartialCustomizable"
          :to="transferPageUrl"
        >
          <CommonButton
            class="offer-page__button"
          >
            Jetzt gestalten
          </CommonButton>
        </router-link>
        <AddToCartButton
          v-else
          ref="addToCartButton"
          :article-number="offer.product.articleNumber"
          class="offer-page__button"
        />
      </div>

      <ShelfGroup
        :items="shelfEntries"
      >
        <template
          v-for="shelfEntry in shelfEntries"
          #[shelfEntry.key]
        >
          <component
            :is="shelfEntry.component"
            :key="shelfEntry.component"
            v-bind="shelfEntry.item"
            :offer="offer"
            :product="offer.product"
            @open-modal="openModal"
            @variant-color-change="variantColorChange"
          />
        </template>
      </ShelfGroup>
      <Modal
        ref="modal"
        class="offer-page__modal"
      >
        <div v-html="modalText" />
      </Modal>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { flatten, uniqBy } from 'lodash';
import { fetchOfferBySlugs } from '@/lib/goliath/offer-by-slugs';
import Header from '@/components/header';
import Footer from '@/components/footer';
import Typography from '@/components/typography';
import ImageGallery from '@/components/image-gallery';
import Prices from '@/components/prices';
import AddToCartButton from '@/components/add-to-cart-button';
import OfferTechnicalDetails from '@/components/product-detail/offer-technical-details';
import OfferHighlights from '@/components/product-detail/offer-highlights';
import OfferDelivery from '@/components/product-detail/offer-delivery';
import OfferCompatibility from '@/components/product-detail/offer-compatibility';
import ShelfGroup from '@/components/product-detail/shelf-group';
import EnergyLabelContainer from '@/components/energy-label-container';
import CounterBadge from '@/components/counter-badge';
import VariantBundleContent from '@/components/product-detail/variant-bundle-content';
import SingleItemConfigurator from '@/components/product-detail/single-item-configurator';
import Modal from '@/components/modal';
import { getSimplifiedPrices } from '@/lib/goliath/simplify-offer';
import generateShelfentries from '@/lib/shelfentries-helpers';
import actions from '@/store/actions';
import CommonButton from '@/components/common-button';

const CUSTOMIZABLE_PARTIAL = 'PARTIAL';

export default {
  name: 'OfferPage',
  components: {
    CommonButton,
    EnergyLabelContainer,
    VariantBundleContent,
    ImageGallery,
    ShelfGroup,
    Typography,
    Prices,
    AddToCartButton,
    OfferCompatibility,
    OfferDelivery,
    OfferHighlights,
    OfferTechnicalDetails,
    CounterBadge,
    SingleItemConfigurator,
    Header,
    Footer,
    Modal,
  },
  data() {
    return {
      offer: null,
      isDisabled: false,
      modalText: '',
    };
  },
  computed: {
    ...mapState({
      categoryHierarchy: (state) => state.shelves.categoryHierarchy,
      cart: (state) => state.cart.cart,
    }),
    offerQuantity() {
      if (this.offer?.product?.articleNumber) {
        const result = this.cart.items.find(
          (cartItem) => cartItem.articleNumber === this.offer.product.articleNumber,
        );

        if (result) {
          return result.quantity;
        }
      }
      return null;
    },
    breadcrumbItems() {
      const items = [{ label: 'Home', path: '/start' }];
      if (!this.offer) return items;

      if (!this.category) return items;
      const { mainCategory } = this.category;
      // https://jira.i22.de/browse/DD-299
      // in case of dein-design offer page, breadcrumb would show
      // dein-design category page which loads far too many cases and therefore
      // far too long. Now it goes straight to the dein-design start page
      if (mainCategory.slug === 'deindesign') {
        items.push({
          label: 'DeinDesign',
          path: '/dein-design/dein-design-start-page',
        });
      } else {
        items.push({
          label: mainCategory.name,
          path: mainCategory.link,
        });
      }

      if (!mainCategory.children) {
        items.push({ label: 'Produkt' });
        return items;
      }

      items.push(
        { label: this.category.name, path: this.category.link },
        { label: 'Produkt' },
      );

      return items;
    },
    category() {
      if (!this.offer.product) return '';
      const { primaryCategory } = this.offer.product.family;
      let category;
      if (primaryCategory) {
        const prependedPrimaryPath = primaryCategory.depth > 0 || primaryCategory.ancestors.length < 1
          ? `/categories/${primaryCategory.path}`
          : `/sub-categories/${primaryCategory.path}`;
        // flatten all available category paths from our category hierachy
        const flattenedCategoryPaths = this.flattenCategories(
          this.categoryHierarchy,
        );
        // match current prepended path with the flattened category path from our category hierachy
        category = flattenedCategoryPaths.find(
          (categoryEntry) => categoryEntry.link === prependedPrimaryPath,
        );
      }
      return category;
    },
    shelfEntries() {
      if (!this.offer) return undefined;
      return generateShelfentries(this.offer);
    },
    energyEfficiency() {
      return this.offer?.product?.energyEfficiency;
    },
    hasDiscount() {
      if (!this.prices) return undefined;
      return !!this.prices.was;
    },
    prices() {
      if (!this.offer?.prices) return undefined;
      return getSimplifiedPrices(this.offer.prices);
    },
    legalTexts() {
      return this.offer?.product?.legalTexts;
    },
    imageData() {
      const baseImages = this.offer?.media
        .filter((m) => m.type === 'IMAGE') || [];
      const additionalImages = this.offer?.product?.bundledProducts?.map((i) => i.teaserImage) || [];
      const uniqueAdditionalImages = uniqBy(additionalImages, 'path');
      return [...baseImages, ...uniqueAdditionalImages];
    },
    hasMultipleImages() {
      if (!this.imageData) return undefined;
      return this.imageData.length > 1;
    },
    productHeading() {
      if (!this.offer || !this.offer.product) return undefined;
      return `${this.offer.product.name}`;
    },
    rebate() {
      if (!this.offer || !this.offer.rebate) return null;
      return this.offer.rebate;
    },
    isPartialCustomizable() {
      return this.offer.product.customizability === CUSTOMIZABLE_PARTIAL;
    },
    transferPageUrl() {
      return `/dein-design/transfer-page?article-number=${this.offer.product.articleNumber}&manufacturer-product-code=${this.offer.product.manufacturerProductCode}`;
    },
  },
  watch: {
    async $route() {
      await this.initializePageFromRoute();
    },
  },
  mounted() {
    this.initializePageFromRoute();
  },
  methods: {
    variantColorChange() {
      this.$refs.addToCartButton.resetButton();
    },
    flattenCategories(categories, pCategory) {
      let results = [];

      (categories || []).forEach((category) => {
        let mainCategory;
        if (pCategory) {
          mainCategory = pCategory;
        } else {
          mainCategory = category;
        }

        results.push({
          name: category.name,
          link: category.link,
          mainCategory,
        });

        const childResults = this.flattenCategories(
          category.children,
          mainCategory,
        );
        results = results.concat(childResults);
      });

      return results;
    },
    async getRebates() {
      try {
        const rebates = (await axios.get('/api/rebates')).data;
        const rebatesArticleNumbers = flatten(
          rebates.map((entry) => entry.applicableProductNumbers),
        );

        return {
          rebates,
          rebatesArticleNumbers,
        };
      } catch (e) {
        return {
          rebates: [],
          rebatesArticleNumbers: [],
        };
      }
    },
    async getOffer(route) {
      if (!route || !route.params) return null;
      const { rebates, rebatesArticleNumbers } = await this.getRebates();

      const offer = await fetchOfferBySlugs({ productSlug: route.params.slug });

      const isRebateOffer = rebatesArticleNumbers
        .includes(offer.product.articleNumber);

      if (!isRebateOffer) return offer;

      return {
        ...offer,
        rebate: rebates.find(
          (entry) => entry.applicableProductNumbers
            .includes(offer.product.articleNumber),
        ),
      };
    },
    async initializePageFromRoute() {
      try {
        this.offer = await this.getOffer(this.$route);
        await this.$nextTick();
        this.$store.dispatch(actions.UPDATE_BREADCRUMB, this.breadcrumbItems);
      } catch (e) {
        console.error({ e });
      }
    },
    openModal(content) {
      this.modalText = content;
      this.$refs.modal.open();
    },
  },
};
</script>

<style lang="scss">
.offer-page {
  &__main {
    padding: 26px 0 0;
  }

  &__heading {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    height: 210px;
    overflow: hidden;
    padding: 0 30px;
  }

  &__heading-block {
    display: flex;
  }

  &__content {
    display: flex;
    justify-content: flex-end;
    margin: 45px 0 40px 0;
  }

  &__button {
    margin-right: 30px;
  }

  &__prices,
  &__energy-label {
    font-size: 40px;
    line-height: 60px;
  }

  &__energy-label {
    align-self: center;
    display: block;
    margin: 0 auto 0 40px;
  }

  &__prices {
    letter-spacing: 0.5px;
    margin-right: 58px;
    text-align: right;
  }

  &__modal {
    position: fixed;
    z-index: 10;
  }
}
</style>
