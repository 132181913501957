<template>
  <router-link
    :to="link"
    class="clay-on-shelf"
  >
    <div class="clay-on-shelf__image-wrapper">
      <div
        ref="ClayOnShelfImage"
        :style="imageStyle"
        class="clay-on-shelf__image"
      />
    </div>
    <div
      class="clay-on-shelf__text-wrapper"
      :style="labelStyle"
    >
      <img
        v-if="brandSrc"
        :src="brandSrc"
        :style="brandStyle"
        :alt="parsedLabel"
        :class="logoImgClass"
        class="clay-on-shelf__logo"
      >
      <Typography
        class="clay-on-shelf__label"
        variant="title-magenta"
        v-html="parsedLabel"
      />
    </div>
  </router-link>
</template>

<script>
import Typography from './typography';

export default {
  name: 'ClayOnShelf',
  components: {
    Typography,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageOffsetLeft: {
      type: Number,
      default: 0,
    },
    imageOffsetRight: {
      type: Number,
      default: 0,
    },
    link: {
      type: String,
      required: true,
    },
    brandSrc: {
      type: String,
      default: null,
    },
    brandWidth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      backgroundPositionStyle: 0,
    };
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.imageSrc})`,
        left: `${this.imageOffsetLeft}px`,
      };
    },
    labelStyle() {
      return {
        marginLeft: `${this.imageOffsetLeft + this.imageOffsetRight}px`,
        marginBottom: this.isDeinDesign ? '-8px' : '0px',
      };
    },
    brandStyle() {
      return {
        width: `${this.brandWidth}px`,
      };
    },
    logoImgClass() {
      const lowerCaseLabel = this.parsedLabel.toLowerCase();
      return `clay-on-shelf__logo--${lowerCaseLabel}`;
    },
    parsedLabel() {
      return this.label;
    },
  },
};
</script>

<style lang="scss">
.clay-on-shelf {
  flex: 0 0 335px;
  max-width: 335px;
  align-items: center;
  display: flex;
  position: relative;
  justify-content: center;

  &__logo {
    max-width: none;
    &--xiaomi {
      position: absolute;
      right: 5rem;
      top: 2.8rem;
    }
  }
  &__offer-image{
    position: absolute;
      left: -40px;
      top: 40px;
  }

  &__image-wrapper {
    height: 100%;

  }

  &__image {
    background-position: center center;
    background-size: auto 100%;
    display: block;
    height: 100%;
    position: relative;
    width: 350px;
  }

  &__label {
    max-width: 180px;
  }
}
</style>
